.col0 {  
  border: 1px solid black;
  border-top: 0;
  border-right: 0;
  font-size: 1.6rem;
}

.col1 {
  border: 1px solid black;
  border-top: 0;
  border-right: 0;
  font-size: 1.6rem;
}

.col0 a, .col1 a {
  text-decoration: none;
  color: black;
}

.col0 a:hover, .col1 a:hover {
  text-decoration: none;
  color: blue;
}

.col2 {
  border: 1px solid black;
  border-top: 0;
  border-right: 0;
  font-size: 1rem;
  padding-left: 5px;
}

.col3 {
  border: 1px solid black;
  border-top: 0;
  font-size: 1rem;
  padding-left: 5px;
}

.col4 {
  border: 1px solid black;
  border-top: 0;
  border-left: 0;
  font-size: 1rem;
  padding-left: 5px;
}

.bookmarkIcon:hover {
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  body {
    font-size: 12px;
  }
  
  .col0 {
    font-size: 1.1rem;
  }
  
  .col1 {
    font-size: 1.1rem;
  }

  .col2 {
    font-size: 0.7rem;
    padding-left: 2px;
  }

  .col3 {
    font-size: 0.7rem;
    padding-left: 2px;
  }
}