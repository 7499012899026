@font-face {
  font-family: testFont;
  src: url("./Adobe_Kaiti_Std.otf") format("opentype");
}

*{
  font-family: testFont,sans-serif
}

body {
  margin: 0;
  padding: 0;
  background-color: #eee;
}

.content-title {
  font-size: 25px;
  font-weight: bold;
  color: #000000;
  margin: 25px 0;
}

.content-video {
  margin-bottom: 25px;
}

.drop-shadow {
  border: 1px solid #ff0000;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.46);
}

.module-menu {
  margin: 20px;
}

.module-card {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.46);
  border: 1px solid #eee;
}

.module-card__image {
  top: 50%;
  width: 100%;
  position: relative;
  transform: translateY(-50%);
}

.app-bar {
  background-color: #333333 !important;
}

.module-card_description {
  background: rgba(156, 39, 176, 0.5) !important;
}
